export const DeepTransLatorLanguages  = [
    { label: "Afrikaans", value: "af", color: "primary" },
    { label: "Albanian", value: "sq", color: "secondary" },
    { label: "Amharic", value: "am", color: "success" },
    { label: "Arabic", value: "ar", color: "danger" },
    { label: "Armenian", value: "hy", color: "warning" },
    { label: "Azerbaijani", value: "az", color: "info" },
    { label: "Basque", value: "eu", color: "dark" },
    { label: "Belarusian", value: "be", color: "light" },
    { label: "Bengali", value: "bn", color: "primary" },
    { label: "Bosnian", value: "bs", color: "secondary" },
    { label: "Bulgarian", value: "bg", color: "success" },
    { label: "Catalan", value: "ca", color: "danger" },
    { label: "Cebuano", value: "ceb", color: "warning" },
    { label: "Chinese (Simplified)", value: "zh-CN", color: "info" },
    { label: "Chinese (Traditional)", value: "zh-TW", color: "dark" },
    { label: "Corsican", value: "co", color: "light" },
    { label: "Croatian", value: "hr", color: "primary" },
    { label: "Czech", value: "cs", color: "secondary" },
    { label: "Danish", value: "da", color: "success" },
    { label: "Dutch", value: "nl", color: "danger" },
    { label: "English", value: "en", color: "warning" },
    { label: "Esperanto", value: "eo", color: "info" },
    { label: "Estonian", value: "et", color: "dark" },
    { label: "Finnish", value: "fi", color: "light" },
    { label: "French", value: "fr", color: "primary" },
    { label: "Frisian", value: "fy", color: "secondary" },
    { label: "Galician", value: "gl", color: "success" },
    { label: "Georgian", value: "ka", color: "danger" },
    { label: "German", value: "de", color: "warning" },
    { label: "Greek", value: "el", color: "info" },
    { label: "Gujarati", value: "gu", color: "dark" },
    { label: "Haitian Creole", value: "ht", color: "light" },
    { label: "Hausa", value: "ha", color: "primary" },
    { label: "Hawaiian", value: "haw", color: "secondary" },
    { label: "Hebrew", value: "he", color: "success" },
    { label: "Hindi", value: "hi", color: "danger" },
    { label: "Hmong", value: "hmn", color: "warning" },
    { label: "Hungarian", value: "hu", color: "info" },
    { label: "Icelandic", value: "is", color: "dark" },
    { label: "Igbo", value: "ig", color: "light" },
    { label: "Indonesian", value: "id", color: "primary" },
    { label: "Irish", value: "ga", color: "secondary" },
    { label: "Italian", value: "it", color: "success" },
    { label: "Japanese", value: "ja", color: "danger" },
    { label: "Javanese", value: "jw", color: "warning" },
    { label: "Kannada", value: "kn", color: "info" },
    { label: "Kazakh", value: "kk", color: "dark" },
    { label: "Khmer", value: "km", color: "light" },
    { label: "Korean", value: "ko", color: "primary" },
    { label: "Kurdish", value: "ku", color: "secondary" },
    { label: "Kyrgyz", value: "ky", color: "success" },
    { label: "Lao", value: "lo", color: "danger" },
    { label: "Latin", value: "la", color: "warning" },
    { label: "Latvian", value: "lv", color: "info" },
    { label: "Lithuanian", value: "lt", color: "dark" },
    { label: "Luxembourgish", value: "lb", color: "light" },
    { label: "Macedonian", value: "mk", color: "primary" },
    { label: "Malagasy", value: "mg", color: "secondary" },
    { label: "Malay", value: "ms", color: "success" },
    { label: "Malayalam", value: "ml", color: "danger" },
    { label: "Maltese", value: "mt", color: "warning" },
    { label: "Maori", value: "mi", color: "info" },
    { label: "Marathi", value: "mr", color: "dark" },
    { label: "Mongolian", value: "mn", color: "light" },
    { label: "Nepali", value: "ne", color: "primary" },
    { label: "Norwegian", value: "no", color: "secondary" },
    { label: "Polish", value: "pl", color: "success" },
    { label: "Portuguese", value: "pt", color: "danger" },
    { label: "Punjabi", value: "pa", color: "warning" },
    { label: "Romanian", value: "ro", color: "info" },
    { label: "Russian", value: "ru", color: "dark" },
    { label: "Serbian", value: "sr", color: "light" },
    { label: "Slovak", value: "sk", color: "primary" },
    { label: "Slovenian", value: "sl", color: "secondary" },
    { label: "Spanish", value: "es", color: "success" },
    { label: "Swahili", value: "sw", color: "danger" },
    { label: "Swedish", value: "sv", color: "warning" },
    { label: "Tagalog", value: "tl", color: "info" },
    { label: "Tamil", value: "ta", color: "dark" },
    { label: "Telugu", value: "te", color: "light" },
    { label: "Thai", value: "th", color: "primary" },
    { label: "Turkish", value: "tr", color: "secondary" },
    { label: "Ukrainian", value: "uk", color: "success" },
    { label: "Urdu", value: "ur", color: "danger" },
    { label: "Uzbek", value: "uz", color: "warning" },
    { label: "Vietnamese", value: "vi", color: "info" }
  ];
  