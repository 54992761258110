import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import configImages from '../../../../config/configImages';
import MenuBar from '../../../Layout/sidebar/menubar';
import fetchMethodRequest from '../../../../config/service';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import configMessages from '../../../../config/configMessages';
import { TypingLoader } from './typingLoader';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import ReadAloud from '../../CommonComponents/ReadAloud';
import CopyText from '../../CommonComponents/CopyText';
import { marked } from 'marked';
import { Controller, useForm } from 'react-hook-form';
import DoFileUpload from '../../../Form/Fields/DoFileUpload';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls';
import ShareChatModal from '../../CommonModals/ShareChatModal';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../App/Loader';
import { CreateAgreementForm } from '../CreateAgreementForm';
const PublicAi = () => {
  const [messages, setMessages] = useState([]);
  let messagesRef = useRef([])
  const [input, setInput] = useState('');
  const chatHistoryRef = useRef(null);
  const menuRef = useRef(null);
  const noProfileImage = configImages.defaultImg;
  const { chatId } = useParams();
  let filterRef = useRef({ "page": 1, "limit": 20, "sortfield": "created", "direction": "desc", "criteria": [{ "key": "chatId", "value": chatId, "type": "eq" }] })
  const [totalCount, setTotalCount] = useState(0)
  let newMessageRef = useRef();
  let previousScrollHeightRef = useRef();
  const [disableButton, setDisableButton] = useState(false);
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  let chatIdRef = useRef(chatId);
  let inputRef = useRef(null);
  const [activeSpeechMessageId, setActiveSpeechMessageId] = useState('');
  const { control, formState, reset } = useForm()
  const { errors } = formState;

  const location = useLocation();
  const { queryType, chat } = location.state || {};
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');

  const [files, setFiles] = useState([])

  const [openShareModal, setOpenShareModal] = useState(false);
  const [chatLink, setChatLink] = useState('');
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [isOpenFormModal, setIsOpenFormModal] = useState(false)

  const getSummaryFileUpload = () => {
    return {
      name: "multiFile",
      fieldName: "multiFile",
      type: "fileUpload",
      placeholder: "MultiFile",
      label: "Upload and Summarize",
      width: "120px",
      addFormOrder: 10,
      editFormOrder: 10,
      stringType: null,
      derivedValue: "multiFile=undefined",
      capitalizeTableText: false,
      sortable: false,
      filter: false,
      id: "multiFile",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "false",
      isMultipleRequired: false,
      required: false,
      globalSearchField: "true",
      controllerId: null,
      fieldType: "fileUpload",
      imagePath: "Files",
      show: true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      isAddFormHidden: false,
      isEditFormHidden: true,
    }
  }


  useEffect(() => {
    let obj = Object.assign(filterRef.current, { "page": 1, "criteria": [{ "key": "chatId", "value": chatId, "type": "eq" }] });
    filterRef.current = obj;
    chatIdRef.current = chatId;
    setMessages([]);
    setIsInitialLoaded(false)
    messagesRef.current = [];
    if (chat && chat.files) {
      setFiles(chat.files);
    } else {
      setFiles([])
    }
    setTimeout(() => {
      insertMessages(obj)
    }, 200);
    setActiveSpeechMessageId(null);
  }, [chatId])

  useEffect(() => {
    if (chatHistoryRef.current && (filterRef.current?.page === 1 || newMessageRef.current)) {
      newMessageRef.current = false
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    } else {
      if (chatHistoryRef?.current) {
        chatHistoryRef.current.scrollTop = chatHistoryRef?.current.scrollHeight - previousScrollHeightRef.current;
      }

    }
  }, [messages]);

  useEffect(() => {
    if (chat?.files?.length > 0) {
      setFiles(chat.files)
    }
  }, [chat]);

  useEffect(() => {
    if (type && !chatId) {
      if (menuRef?.current) {
        menuRef.current?.getSharedChats()
      }
    }
    if (!type && !chatId) {
      if (menuRef?.current) {
        menuRef.current?.createChat(false)
      }
    }
    askAQuestionOrDraft();
    if (queryType !== 'GenerateDraft') {
      setSearchPlaceholder('')
    }
  }, [location])

  const createChat = (resp) => {
    if (menuRef?.current) {
      menuRef.current?.createChat(true, resp)
    }
  }

  const updateLastMessage = (res = {
    messages: {
      question: input, answer: `
        <div class="alert alert-danger text-center" role="alert">
            ${configMessages.deafultErrorMessage}
        </div>` , 'type': 'error'
    }
  }) => {
    let updatedMessages = messages
    if (messages[messages.length - 1]?.answer === 'loader') {
      updatedMessages = messages.slice(0, -1);
      updatedMessages.push(res.messages)
    }
    else {
      updatedMessages.push(res.messages)
    }
    setMessages([...updatedMessages]);
  }

  const handleSend = (event, query, type) => {
    if (!type) {
      if (!input || disableButton) {
        return;
      }
    }
    if (type == 'regenerate') {
      if (!query) {
        return
      }
    }
    let url;
    if (chatId) {
      url = `${apiCalls.chatWithAi}?chatId=${chatId}`
    } else {
      url = apiCalls.chatWithAi
    }
    let body = { "query": query ? query?.trim() : input?.trim(), queryType: queryType }
    if (chat?.conversationType === "Upload and Summarize" && chatId) {
      url = `${apiCalls.fileRead}?chatId=${chatId}`
      body.conversationType = "Upload and Summarize"
    }
    setDisableButton(true);
    const userMessage = { question: query ? query : input, answer: 'loader' };
    setInput('');
    setMessages([...messages, userMessage]);
    fetchMethodRequest('POST', url, body)
      .then((res) => {
        if (chatIdRef.current && res?.messages?.chatId && res?.messages?.chatId !== chatIdRef.current) {
          setDisableButton(false);
          return
        }
        if (res && res.messages) {
          newMessageRef.current = true;
          updateLastMessage(res)
          if (!chatIdRef.current) {
            createChat(res)
          }
        } else {
          updateLastMessage()
        }
        setDisableButton(false)
      }).catch((err) => {
        console.error("Fetch error:", err);
        updateLastMessage()
        showToasterMessage(configMessages.deafultErrorMessage, 'error');
      });
  };

  function insertMessages(defaultFilter) {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current;
    if (!chatId) return;
    let url = `${apiCalls.messages}?filter=${JSON.stringify(finalFilter)}`;
    if (type && chat) {
      url = `${apiCalls.messages}?filter=${JSON.stringify(finalFilter)}&sharedChatId=${chat.sharedChatId}`
    }
    fetchMethodRequest('GET', url).then(async (resp) => {
      if (resp && resp.messages?.length > 0) {
        if (resp.pagination?.page) {
          if (resp.pagination?.page === 1) setIsInitialLoaded(true);
          let obj = Object.assign(finalFilter, { "page": resp.pagination?.page });
          filterRef.current = obj
        }
        if (resp.pagination?.totalCount) {
          setTotalCount(resp.pagination?.totalCount)
        }
        if (finalFilter.page === 1) {
          messagesRef.current = resp.messages?.reverse()
          setMessages(messagesRef.current);
        } else {
          previousScrollHeightRef.current = chatHistoryRef.current?.scrollHeight;
          messagesRef.current = [...resp.messages?.reverse(), ...messagesRef.current]
          setMessages(messagesRef.current);
        }
      }
    })
  };

  const onScrollTop = (e) => {
    const bottom = e.target.scrollTop == 0;
    if (bottom && messages.length < totalCount && isInitialLoaded) {
      let currentFilter = JSON.parse(JSON.stringify(filterRef.current))
      currentFilter.page = currentFilter.page + 1;
      insertMessages(currentFilter)

    }
  }

  const askAQuestionOrDraft = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleActiveSpeectMessage = (messageId) => {
    setActiveSpeechMessageId(messageId)
  }

  const onUploadAndSummarize = (response) => {
    if (response) {
      createChat(response)
    }
  }
  const closeDeleteModal = () => {
    setOpenShareModal(false)
    setChatLink('');
  }

  const getShareChatModal = () => {
    return <ShareChatModal
      openSharedModal={openShareModal}
      closeDeleteModal={closeDeleteModal}
      chatLink={chatLink}
      chatId={chatId}
    />
  }

  const showLoaderAndDisable = (value) => {
    setIsLoading(value)
    setDisableButton(value)
  }

  let getFileUpload = (i, item) => {
    const chooseOptions = {
      icon: 'pi pi-upload',
      iconOnly: false,
      className: 'custom-fileupload'
    };
    const bytesPerMB = 1024 * 1024;
    let fileSize = item.maxFileSize * bytesPerMB;
    let url = 'files/uploadAndSummarize?type=files';
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoFileUpload
              url={url}
              formType={'add'}
              showPreview={true}
              input={field}
              id={field.id}
              label={item.label}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              type={'Public AI'}
              isMultiple={true}
              maxFileSize={fileSize}
              hideLabel={true}
              autoSubmit={true}
              modeType={'basic'}
              chooseOptions={chooseOptions}
              item={item}
              onSuccess={onUploadAndSummarize}
              customFunction={showLoaderAndDisable}
              parameters={{ beforeUpload: true, afterUpload: false }}
            />)}
        />
      </div>
    )
  }

  const handleRegenerate = (message) => {
    handleSend('', message.question, 'regenerate')
  }

  const shareChat = (messageId = null) => {
    let body = {
      chatId: chatId
    }
    if (messageId) {
      body.messageId = messageId
    }

    fetchMethodRequest('POST', `${apiCalls.shareChat}`, body).then((res) => {
      if (res && res.link) {
        setChatLink(res);
        setOpenShareModal(true);
      }
    })
  }

  const messageShare = (message) => {
    shareChat(message._id)
  }

  const openCreateAgreementForm = () => {
    setIsOpenFormModal(true);
  }

  return (
    <>
      <div className='summary-search-containar' style={{ backgroundColor: '#fff' }}>
        <div className='col-sm-12 d-flex align-items-start'>
          <MenuBar setMessages={setMessages} chatId={chatId} ref={menuRef} searchType={'publicAi'} defaultSearchFilter={{ key: 'chatType', value: "Public AI", type: 'eq' }} type={type} shareChats={true} />
          <div className='col-sm-9 justify-content-center align-items-center chat-bot-main'>
            <div className="chatbot-container">
              <div className='d-flex justify-content-center align-items-center gap-2 button-options'>
                <Button size="sm" outlined rounded onClick={() => setSearchPlaceholder('Ask a question')}>
                  <Link to={'/publicAi'} state={{ queryType: 'AskQuestion' }} className='color-white'>Ask a question</Link>
                </Button >
                <Button size="sm" outlined rounded onClick={() => setSearchPlaceholder('Generate drafts')}>
                  <Link to={'/publicAi'} state={{ queryType: 'GenerateDraft' }} className='color-white'>Generate a draft</Link>
                </Button>
                {/* No Need This For Now  */}
                {false && getFileUpload(-1, getSummaryFileUpload())}
                <Button size="sm" outlined rounded onClick={() => openCreateAgreementForm()}>
                  Create Agreement
                </Button>
                {chatId && !type &&
                  <Button size='sm' outlined rounded className='share-button' onClick={() => shareChat()}>
                    <FontAwesomeIcon
                      icon={faShareAlt}
                      size='1x'
                      data-toggle='tool-tip'
                      title='Share Chat'
                    />
                  </Button>}
              </div>
              {
                chatId || messages?.length > 0 ?
                  <>
                    <div className="chatbot-messages" ref={chatHistoryRef} onScroll={onScrollTop}>
                      <Loader customClass={'messages-loader'} loader={isLoading} />
                      <div className='d-flex justify-content-end align-items-center' key={'file'}>
                        {files?.length > 0 &&
                          <>
                            <div className={'message user text-align-right'}>
                              {files.map(file => {
                                return <a href={config.imgUrl + file?.path}>
                                  <Card className='folder-box p-0 mx-2 w-auto'>
                                    <CardBody className='tableCardBody text-align-left p-1 d-flex justify-content-between align-items-center'>
                                      <FontAwesomeIcon
                                        icon={file.type === 'docx' ? 'file-word' : 'file-pdf'}
                                        className='iconsize'
                                        size='1x'
                                        style={{ color: file.type && file.type === 'pdf' ? 'brown' : (file.type === 'docx') ? '#1292ee' : 'inherit', backgroundColor: 'transparent', fontSize: '2rem' }}
                                        data-toggle='tool-tip'
                                        title='Settings'
                                      />
                                      <span style={{ color: 'black' }}>{file.name}</span>
                                    </CardBody>
                                  </Card>
                                </a>
                              })}
                            </div>
                            <div className='icon-style mx-2'>
                              <img src={noProfileImage} alt="" />
                            </div>
                          </>
                        }

                      </div>
                      {messages.map((message, index) => (
                        <>
                          <div className='d-flex justify-content-end align-items-center' key={'question' + index}>
                            <div className={'message user'}>{message?.question}</div>
                            <div className='icon-style mx-2'>
                              <img src={noProfileImage} alt="" />
                            </div>
                          </div>
                          <div className='d-flex justify-content-start align-items-start' key={'answer' + index}>
                            <div className='icon-style mx-2 mt-1'>
                              <FontAwesomeIcon
                                className='genderIconAlignment'
                                color='white'
                                icon='comment-dots'
                                data-toggle="tool-tip"
                                title='Send'
                              />
                            </div>
                            {

                              message?.answer && message?.answer !== 'loader' ?
                                <div className='message bot'>
                                  <div dangerouslySetInnerHTML={{ __html: message?.type !== 'error' ? marked(message.answer) : message.answer }} ></div>
                                  <div className='d-flex justify-content-start align-items-center gap-1 mt-1 message-options-container' style={index === messages.length - 1 ? { visibility: 'visible' } : {}}>
                                    <span className='message-options'>
                                      <ReadAloud text={message.answer} onActivate={(msg) => handleActiveSpeectMessage(msg === 'cancel' ? null : message._id)} isActive={activeSpeechMessageId == message._id} />
                                    </span>
                                    <span className='message-options'>
                                      <CopyText text={message.answer} />
                                    </span >
                                    <span className='message-options'>
                                      <CopyText text={message.answer} copyType={'TextCopy'} iconName={'copy'} />
                                    </span >
                                    <span className='message-options' onClick={() => messageShare(message)}>
                                      <FontAwesomeIcon
                                        icon='share'
                                        className='pl-1' size='lg'
                                        data-toggle="tool-tip" title="Share"
                                      />
                                    </span>
                                    {message.downloadPath && <span className='message-options'>
                                      <a href={config.imgUrl + message.downloadPath} download={config.imgUrl + message.downloadPath} target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon
                                          icon='download'
                                          className='pl-1'
                                          size='lg'
                                          data-toggle="tooltip"
                                          title="Download PDF"
                                        />
                                      </a>
                                    </span>
                                    }
                                    {index === messages.length - 1 && !type && <span className='message-options'>
                                      <FontAwesomeIcon
                                        icon='sync-alt'
                                        className='pl-1' size='lg'
                                        data-toggle="tool-tip" title="Regenerate"
                                        onClick={() => handleRegenerate(message)}
                                      />
                                    </span>}
                                  </div>
                                </div>
                                : message?.answer === 'loader' ?
                                  <div className='message bot'>
                                    <TypingLoader />
                                  </div> : <div class="alert alert-danger text-center" role="alert">
                                    {configMessages.deafultErrorMessage}
                                  </div>
                            }

                          </div>
                        </>
                      ))}
                    </div>

                  </> :
                  <div className='new-chat'>
                    <img src={configImages.dosystemsImg} alt="" />
                    <Loader customClass={'position-relative'} loader={isLoading} />
                  </div>
              }
            </div>
            {type !== 'shared' && <div className="chat-bot-input-wraapper col-sm-12 p-2 mt-2">
              <div className="chatbot-input col-sm-12 mt-3">
                <input
                  ref={inputRef}
                  type="text"
                  value={input}
                  placeholder={searchPlaceholder ? searchPlaceholder : 'Ask a question'}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                />
                <button onClick={handleSend} disabled={disableButton}>
                  <FontAwesomeIcon
                    className='genderIconAlignment'
                    color='#fff'
                    icon='paper-plane'
                    data-toggle="tool-tip"
                    title='Send'
                  />
                </button>
              </div>
            </div>}
          </div>
        </div>
      </div>
      {chatLink && getShareChatModal()}
      {isOpenFormModal && <CreateAgreementForm createChat={createChat} isOpenFormModal={isOpenFormModal} setIsOpenFormModal={setIsOpenFormModal} />}
    </>
  );
};

export default PublicAi;
