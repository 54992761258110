import React, { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DeepTransLatorLanguages } from '../Translator.data'
import { Controller, useForm } from 'react-hook-form'
import DoSelectField from '../../../Form/Fields/DoSelectField'
import { Button } from 'primereact/button'
import fetchMethodRequest from '../../../../config/service'
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage'
import FilePreview from './FilePreview' 
import { MoonLoader } from 'react-spinners';

export const TranslateFiles = (props) => {


  const { control, formState, watch , reset} = useForm();
  const { errors } = formState;
  const [fileBuffer, setFileBuffer] = useState('');
  const [fileType, setFileType] = useState('');
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const language = watch('language');
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false)


  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length > 1) {
      setErrorMessage('Only one file can translate once')
    }
    // Validate accepted file types
    const isValid = acceptedFiles.every(file => {
      const validTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      return validTypes.includes(file.type);
    });
    if (isValid) {
      setAcceptedFiles(acceptedFiles);
    } else {
      setErrorMessage('Please upload only PDF or DOCX files.');
    }

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      // No Use of these Events For now
      // reader.onabort = () => console.log('file reading was aborted')
      // reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
      }
      reader.readAsArrayBuffer(file)
    })

  }, [])

  const selectLanguage = {
    name: "language",
    fieldName: "language",
    type: "dropDown",
    placeholder: "Select Language",
    value: "",
    label: "Destination Language",
    width: "120px",
    addFormOrder: 6,
    editFormOrder: 10,
    stringType: null,
    derivedValue: "language=undefined",
    capitalizeTableText: false,
    sortable: false,
    filter: false,
    id: "language",
    displayinaddForm: "true",
    displayineditForm: "true",
    displayinregisterForm: "",
    displayinlist: "true",
    isFieldRequired: "false",
    required: false,
    displayOptionsInActions: "",
    globalSearchField: "true",
    controllerId: null,
    isClickable: "",
    isAddToIndex: false,
    isBulkUploadField: true,
    options: DeepTransLatorLanguages,
    fieldType: "dropDown",
    filterElement: DeepTransLatorLanguages,
    show: true,
    showOrHideFields: [],
    mobile: true,
    displayInSettings: true,
    isAddFormHidden: false,
    isEditFormHidden: false,
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });

  const handleClick = () => {
    fileInputRef.current.click();
  };

  let getDropdown = (item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              markReq={item.required}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
              item={item}
              screenName={'Translate'}
            />)}
        />
      </div>
    )
  }


  const handleFilesUpload = (files) => {
    if(fileBuffer || fileType) {
      setFileBuffer('');
      setFileType('')
    }
    if (!language) {
      setErrorMessage('Please Select the Destination Language');
      return
    }
    setIsLoading(true)
    files.forEach((file, index) => {
      const formData = new FormData();
      formData.append('file', file);
      let url = 'chats/documentTranslation';
      if (language) {
        url = `chats/documentTranslation?language=${language}`
      }
      fetchMethodRequest("POST", url, formData, 'upload', true)
        .then(async (response) => {
          if (response.translations[0].fileBuffer) {
            setFileBuffer(response.translations[0].fileBuffer);
            setFileType(response?.translations[0]?.fileType)
            reset()
            if (fileInputRef.current) {
              fileInputRef.current.value = ''; // Reset the input value
            }
            setAcceptedFiles([])
          }
          setIsLoading(false)
        }).catch((err) => {
          setIsLoading(false)
          console.log('err', err)
        });
    });
  };

  const handleTranslateButton = () => {
    if (acceptedFiles.length > 0) {
      handleFilesUpload(acceptedFiles)
    }
  }

  const closePreview = () => {
    setFileBuffer('');
    setFileType('')
  }


  return (
    <div>
      <h2>Translate Files</h2>
      <div className='d-flex justify-content-evenly'>
      <div className='d-flex flex-column justify-content-center align-items-center height-70vh w-50vw'>
        <div className='w-100 d-flex justify-content-start mb-4'>
          {getDropdown(selectLanguage)}
        </div>
        <div>
          <p>Drop your Files</p>
          <div
            {...getRootProps()}
            style={{ ...dropzoneContainerStyle, ...(isDragActive ? activeDropzoneStyle : {}) }}
            onClick={handleClick}  // Open file manager on click
          >
            <input {...getInputProps()} ref={fileInputRef} multiple />
            <div className="upload-icon-wrapper">
              <FontAwesomeIcon
                icon={faCloudUploadAlt}
                size="4x"
                color={isDragActive ? "rgb(13, 110, 253)" : '#0d6efd'}
                className={`upload-icon ${isDragActive ? 'bounce-animation' : ''}`}
              />
            </div>
            <div className="text-wrapper">
              <div className="drop-text-container ms-2">
                <p style={dropTextStyle}>Translate {acceptedFiles[0]?.name ? acceptedFiles[0]?.name :  "Files"}</p>
              </div>
            </div>
          </div>
        </div>
        {errorMessage && <div className='d-flex justify-content-start mt-2'>
          <small className='text-danger'>{errorMessage}</small>
        </div>}
        <div className='w-100 d-flex justify-content-end mt-4'>
          {isLoading ? 
          <Button>Translating &nbsp; <MoonLoader color='#fff' size={18}/> </Button>
          :<Button onClick={handleTranslateButton} disabled={!(language && acceptedFiles?.length > 0) }>Translate</Button>}
        </div>
      </div>
      {fileBuffer && fileType && <div>
         <FilePreview fileBuffer={fileBuffer} fileType={fileType} closePreview={closePreview}/>
      </div>}
    </div>
    </div>
  );
}

const dropzoneContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 10,
  borderColor: '#ccc',
  borderStyle: 'dashed',
  backgroundColor: '#f9f9f9',
  cursor: 'pointer',
  height: '30vh',
  minHeight: '200px',
  width: '50vw',
  transition: 'background-color 0.3s, border-color 0.3s'
};

const activeDropzoneStyle = {
  borderColor: '#0d6efd',
  backgroundColor: '#e7f3ff',
};

const dropTextStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#333',
};
