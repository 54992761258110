import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TranslateFiles } from './components/TranslateFiles';

const translatefiles = (props,{ t }) => (
  <Container  >
    <TranslateFiles  {...props} />
  </Container>
);

translatefiles.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(translatefiles);
