import React, { useEffect, useState, useRef } from 'react';
import { saveAs } from 'file-saver';
import { Button } from 'primereact/button';
import { faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as docx from 'docx-preview';

function FilePreview({ fileBuffer, fileType, closePreview }) {
  const [fileURL, setFileURL] = useState(null);
  const docxContainerRef = useRef(null);

  useEffect(() => {
    if (fileBuffer && fileBuffer.data) {
      const byteArray = new Uint8Array(fileBuffer.data);
      const blob = new Blob([byteArray], { type: fileType });
      const url = URL.createObjectURL(blob);
      setFileURL(url);

      if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const renderDocx = async () => {
          const arrayBuffer = await blob.arrayBuffer();
          await docx.renderAsync(arrayBuffer, docxContainerRef.current, null, {
            style: `
              .docx-container {
                width: 100%;
                max-width: 80%; /* Constrain max width */
                margin: 0 auto;
                transform: scale(0.85); /* Reduce to fit view */
                transform-origin: top center;
                overflow: hidden;
                padding: 15px;
                background: #f9f9f9;
                border-radius: 8px;
              }
              .docx img {
                max-width: 100%;
                height: auto;
              }
              .docx table {
                width: 100%;
                border-collapse: collapse;
                font-size: 0.9em; /* Smaller font size for tables */
              }
              .docx th, .docx td {
                border: 1px solid #ddd;
                padding: 8px;
              }
              .docx h1, .docx h2, .docx h3, .docx p {
                margin: 5px 0;
                font-size: 0.95em; /* Slightly smaller font */
              }
            `,
          });
        };
        renderDocx();
      }

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [fileBuffer, fileType]);

  const handleDownload = () => {
    const byteArray = new Uint8Array(fileBuffer.data);
    const blob = new Blob([byteArray], { type: fileType });
    saveAs(blob, `downloaded_file.${fileType === 'application/pdf' ? 'pdf' : 'docx'}`);
  };

  const handleHide = () => {
    closePreview();
  };

  return (
    <div style={{width : '30vw'}}>
      {fileType === 'application/pdf' && fileURL && (
        <iframe 
          src={`${fileURL}#toolbar=0`} 
          title="PDF Preview" 
          width="100%" 
          height="600px" 
          style={{
            border: 'none',
            borderRadius: '8px',
            overflow: 'hidden',
            margin: '0 auto',
          }} 
        />
      )}

      {fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && (
        <div 
          ref={docxContainerRef} 
          className="docx-container"
          style={{
            width: '100%', 
            maxWidth: '90%', // Responsive width
            margin: '0 auto',
            overflow: 'hidden',
            boxSizing: 'border-box',
        }}
      />
      )}

      <div>
        <Button onClick={handleDownload} style={{ margin: '10px 0' }}>
          <FontAwesomeIcon icon={faDownload} size="1x" title="Download" />
        </Button>
        <Button onClick={handleHide} className="ms-2" style={{ margin: '10px 0' }}>
          <FontAwesomeIcon icon={faTimes} size="1x" title="Close Preview" />
        </Button>
      </div>
    </div>
  );
}

export default FilePreview;
