import { useRef, useState } from "react"
import FormModal from "../../Form/FormModal"
import config from "../../../config/config";

export const CreateAgreementForm = (props) => {

    let formModalRef = useRef('')
    const [isOpenFormModal ,setIsOpenFormModal] = useState(props.isOpenFormModal);

    const getFormFields = () => {

      return [
        {
          name: "party1",
          fieldName: "party1",
          type: "text",
          placeholder: "Party1",
          value: "",
          label: "Party1",
          width: "120px",
          addFormOrder: 1,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "party1=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "party1",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: false,
          displayinlist: "true",
          isFieldRequired: "false",
          required: true,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: false,
          isBulkUploadField: true,
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          fieldType: "Link",
          style: {
            color: "#0e4768",
            cursor: "pointer",
            textTransform: "capitalize",
          },
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
        {
          name: "party2",
          fieldName: "party2",
          type: "text",
          placeholder: "Party2",
          value: "",
          label: "Party2",
          width: "120px",
          addFormOrder: 2,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "party2=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "party2",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: false,
          displayinlist: "true",
          isFieldRequired: "false",
          required: true,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: false,
          isBulkUploadField: true,
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
        {
          name: "party1Address",
          fieldName: "party1Address",
          type: "textarea",
          placeholder: "Party1 Address",
          value: "",
          label: "Party1 Address",
          width: "120px",
          addFormOrder: 3,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "party1Address=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "party1Address",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: "",
          displayinlist: "true",
          isFieldRequired: "false",
          required: true,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: false,
          isBulkUploadField: true,
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
        {
          name: "party2Address",
          fieldName: "party2Address",
          type: "textarea",
          placeholder: "Party2 Address",
          value: "",
          label: "Party2 Address",
          width: "120px",
          addFormOrder: 4,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "party2Address=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "party2Address",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: "",
          displayinlist: "true",
          isFieldRequired: "false",
          required: true,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: false,
          isBulkUploadField: true,
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
        {
          name: "agreementType",
          fieldName: "agreementType",
          type: "dropDown",
          placeholder: "Agreement Type",
          value: "",
          label: "Agreement Type",
          width: "120px",
          addFormOrder: 6,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "agreementType=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "agreementType",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: "",
          displayinlist: "true",
          isFieldRequired: "false",
          required: true,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: false,
          isBulkUploadField: true,
          options: [
            {
              label: "Rental Agreement",
              value: "Rental Agreement",
              color: "primary",
              dependentField: "",
              id: "dpc",
            },
            {
              label: "Employment Agreement",
              value: "Employment Agreement",
              color: "secondary",
              dependentField: "",
              id: "dpc",
            },
            {
              label: "Partnership Agreement",
              value: "Partnership Agreement",
              color: "success",
              dependentField: "",
              id: "dpc",
            },
            {
              label: "Service Agreement",
              value: "Service Agreement",
              color: "warning",
              dependentField: "",
              id: "dpc",
            },
            {
              label: "Confidentiality Agreement",
              value: "Confidentiality Agreement",
              color: "info",
              dependentField: "",
              id: "dpc",
            },
          ],
          fieldType: "dropDown",
          filterElement: [
            {
              label: "Rental Agreement",
              value: "Rental Agreement",
              color: "primary",
              dependentField: "",
              id: "dpc",
            },
            {
              label: "Employment Agreement",
              value: "Employment Agreement",
              color: "secondary",
              dependentField: "",
              id: "dpc",
            },
            {
              label: "Partnership Agreement",
              value: "Partnership Agreement",
              color: "success",
              dependentField: "",
              id: "dpc",
            },
            {
              label: "Service Agreement",
              value: "Service Agreement",
              color: "warning",
              dependentField: "",
              id: "dpc",
            },
            {
              label: "Confidentiality Agreement",
              value: "Confidentiality Agreement",
              color: "info",
              dependentField: "",
              id: "dpc",
            },
          ],
          dependent: [],
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
        {
          name: "confidentiality",
          fieldName: "confidentiality",
          type: "checkbox",
          placeholder: "Confidentiality",
          value: false,
          label: "Confidentiality Clause",
          width: "120px",
          addFormOrder: 10,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "confidentiality=confidentiality=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "confidentiality",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: "",
          displayinlist: "true",
          isFieldRequired: "false",
          required: false,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: "",
          isBulkUploadField: true,
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
        {
          name: "termsAndConditions",
          fieldName: "terms",
          type: "textarea",
          placeholder: "Terms",
          value: "",
          label: "Terms",
          width: "120px",
          addFormOrder: 7,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "terms=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "terms",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: "",
          displayinlist: "true",
          isFieldRequired: "false",
          required: true,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: false,
          isBulkUploadField: true,
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
        {
          name: "paymentTerms",
          fieldName: "paymentTerms",
          type: "textarea",
          placeholder: "Payment Terms",
          value: "",
          label: "Payment Terms (if applicable)",
          width: "120px",
          addFormOrder: 8,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "paymentTerms=paymentTerms=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "paymentTerms",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: "",
          displayinlist: "true",
          isFieldRequired: "false",
          required: false,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: false,
          isBulkUploadField: true,
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
        {
          name: "obligationsOfTheParty",
          fieldName: "obligations",
          type: "textarea",
          placeholder: "Obligations",
          value: "",
          label: "Obligations of the Parties:",
          width: "120px",
          addFormOrder: 9,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "obligations=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "obligations",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: "",
          displayinlist: "true",
          isFieldRequired: "false",
          required: false,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: false,
          isBulkUploadField: true,
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
        {
          name: "terminationConditions",
          fieldName: "termination",
          type: "textarea",
          placeholder: "Termination",
          value: "",
          label: "Termination Conditions",
          width: "120px",
          addFormOrder: 11,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "termination=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "termination",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: false,
          displayinlist: "true",
          isFieldRequired: "false",
          required: false,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: false,
          isBulkUploadField: true,
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
        {
          name: "description",
          fieldName: "description",
          type: "textarea",
          placeholder: "Description",
          value: "",
          label: "Description of the Parties:",
          width: "120px",
          addFormOrder: 9,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "description=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "description",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: "",
          displayinlist: "true",
          isFieldRequired: "false",
          required: false,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: false,
          isBulkUploadField: true,
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
        {
          name: "governingLaw",
          fieldName: "Governing Law",
          type: "text",
          placeholder: "Governing Law",
          value: "",
          label: "Governing Law",
          width: "120px",
          addFormOrder: 12,
          editFormOrder: 10,
          stringType: null,
          derivedValue: "Governing Law=undefined",
          capitalizeTableText: false,
          sortable: false,
          filter: false,
          id: "Governing Law",
          displayinaddForm: "true",
          displayineditForm: "true",
          displayinregisterForm: false,
          displayinlist: "true",
          isFieldRequired: "false",
          required: false,
          displayOptionsInActions: "",
          globalSearchField: "true",
          controllerId: null,
          isClickable: "",
          isAddToIndex: false,
          isBulkUploadField: true,
          show: true,
          showOrHideFields: [],
          mobile: true,
          displayInSettings: true,
          isAddFormHidden: false,
          isEditFormHidden: false,
        },
      ];
    };
  
    const closeFormModal = () => {
      props.setIsOpenFormModal(false);
    }

    const getDataFromServer = () => {

    }
    const onSuccessSave = (response) => {
      props.createChat(response)
    }
    const getFormModal = () => {

        return (
          <FormModal
            onRef={(ref) => formModalRef = ref}
            tableRowData={{}}
            openFormModal={isOpenFormModal}
            closeFormModal={closeFormModal}
            type={'Create Agreement'}
            formType={'add'}
            formFields={getFormFields}
            getDataFromServer={getDataFromServer}
            apiUrl={ 'files/generateDoc'}
            routeTo={'myDocuments'}
            displayName={'Create Agreement'}
            displayViewOfForm={'modal'}
            selectedId={props?.selectedId}
            onSuccessSave={onSuccessSave}
          />
        )
      }


    return <>
        {getFormModal()}
    </>

}